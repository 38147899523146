import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { newContract, updateEtapaUno } from "../../API/ApiContratos";

const validationSchema = yup.object({
  titular: yup.string("Ingresa Titular").required("Titular requerido"),
  noContrato: yup
    .string("Ingresa número de contrato")
    .required("Número de contrato requerido"),
  fechaContrato: yup
    .string("Ingresa fecha contrato")
    .required("Fecha requerida"),
  domicilio: yup.string("Ingresa Domicilio").required("Domicilio requerido"),
  colonia: yup.string("Ingresa Colonia").required("Colonia requerida"),
  cp: yup
    .string("Ingresa Código Postal")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "5 dígitos para código postal")
    .max(5, "5 dígitos para código postal")
    .required("Código postal requerido"),
  hPoblacion: yup.string("Ingresa población").required("Población requerida"),
  entreCalle1: yup.string("Ingresa Calle").required("Calle requerida"),
  entreCalle2: yup.string("Ingresa Calle").required("Calle requerida"),
  telefono: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),
});

export const EditContratosEtapaUnoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);

        getMunicipios(user, props.finalData.etapaUno[0].idestado).then(
          (resultado) => {
            setMunicipios(resultado.data);
          }
        );
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, [props]);

  const formik = useFormik({
    initialValues: {
      titular: props.finalData.etapaUno[0].titular,
      noContrato: props.finalData.etapaUno[0].noContrato,
      fechaContrato: props.finalData.etapaUno[0].fechaContrato,
      status: props.finalData.etapaUno[0].status,
      serie: props.finalData.etapaUno[0].serie,
      observaciones: props.finalData.etapaUno[0].observaciones,
      idestado: props.finalData.etapaUno[0].idestado,
      idciudad: props.finalData.etapaUno[0].idciudad,
      domicilio: props.finalData.etapaUno[0].domicilio,
      entreCalle1: props.finalData.etapaUno[0].entreCalle1,
      entreCalle2: props.finalData.etapaUno[0].entreCalle2,
      colonia: props.finalData.etapaUno[0].colonia,
      cp:
        props.finalData.etapaUno[0].cp == "null"
          ? 0
          : props.finalData.etapaUno[0].cp,
      telefono: props.finalData.etapaUno[0].telefono,
      hPoblacion: props.finalData.etapaUno[0].hPoblacion,
      coloniaTrabajo: props.finalData.etapaUno[0].coloniaTrabajo,
      telefonoTrabajo: props.finalData.etapaUno[0].telefonoTrabajo,
      domicilioTrabajo: props.finalData.etapaUno[0].domicilioTrabajo,
      tituloPropiedad: props.finalData.etapaUno[0].tituloPropiedad,
      cortesia: props.finalData.etapaUno[0].cortesia,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      updateEtapaUno(user, values, props.finalData.etapaUno[0].id).then(
        (resultado) => {
          if (resultado.status == 200) {
            toast.success("Datos Generales Actualizados Correctamente");
          } else {
            toast.error(resultado.message);
          }
        }
      );
      setIsDisabled(false);
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      //props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="container-fluid p-2">
      <div className="row">
        <div className="col-12">
          {isDisabled == true ? (
            <>
              <Alert className="mb-3" severity="info">
                Procesando información...
              </Alert>
            </>
          ) : null}
          <form onSubmit={formik.handleSubmit} className="">
            <div className="container-fluid g-0">
              <div className="row row-cols-md-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="titular"
                    name="titular"
                    label="Nombre Titular"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.titular}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.titular && Boolean(formik.errors.titular)
                    }
                    helperText={formik.touched.titular && formik.errors.titular}
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div className="row row-cols-md-6">
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Serie
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.serie}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={"C"}
                      inputProps={{
                        name: "serie",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={"C"}>C</option>
                      <option value={"SM"}>SM</option>
                      <option value={"BB"}>BB</option>
                      <option value={"S"}>S</option>
                      <option value={"P"}>P</option>
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="noContrato"
                    name="noContrato"
                    label="Número"
                    type={"number"}
                    min="1"
                    className="mb-3"
                    value={formik.values.noContrato}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.noContrato &&
                      Boolean(formik.errors.noContrato)
                    }
                    helperText={
                      formik.touched.noContrato && formik.errors.noContrato
                    }
                    disabled={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaContrato"
                    name="fechaContrato"
                    label="fechaContrato"
                    type="date"
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaContrato}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaContrato &&
                      Boolean(formik.errors.fechaContrato)
                    }
                    helperText={
                      formik.touched.fechaContrato &&
                      formik.errors.fechaContrato
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Status Contrato
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={"1"}
                      inputProps={{
                        name: "status",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={"1"}>Activo</option>
                      <option value={"2"}>Cancelado</option>
                      <option value={"3"}>Inactivo</option>
                      <option value={"4"}>Otorgado</option>
                      <option value={"5"}>Pagado</option>
                      <option value={"6"}>Restructurado</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="container-fluid g-0">
              <div className="row row-cols-md-6">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="domicilio"
                    name="domicilio"
                    label="Domicilio"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.domicilio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.domicilio &&
                      Boolean(formik.errors.domicilio)
                    }
                    helperText={
                      formik.touched.domicilio && formik.errors.domicilio
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Estado
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idestado}
                      disabled={isDisabled}
                      defaultValue={30}
                      inputProps={{
                        name: "idestado",
                        id: "uncontrolled-native",
                      }}
                      onChange={(e) => {
                        formik.handleChange(e);
                        getMunicipios(user, e.target.value).then(
                          (resultado) => {
                            if ((resultado.status = 200)) {
                              setMunicipios(resultado.data);
                            } else {
                              setMunicipios([]);
                            }
                          }
                        );
                      }}
                    >
                      <option aria-label="Seleccionar Estado">
                        Seleccionar Estado
                      </option>

                      {estados.map((estado, index) => (
                        <option key={index} value={estado.idestado}>
                          {estado.nombreEstado}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Municipio
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idciudad}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={30}
                      inputProps={{
                        name: "idciudad",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option aria-label="Seleccionar Ciudad">
                        Seleccionar Ciudad
                      </option>

                      {municipios.map((ciudad, index) => (
                        <option key={index} value={ciudad.idciudad}>
                          {ciudad.nombreCiudad}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="colonia"
                    name="colonia"
                    label="Colonia"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.colonia}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.colonia && Boolean(formik.errors.colonia)
                    }
                    helperText={formik.touched.colonia && formik.errors.colonia}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="cp"
                    name="cp"
                    label="Código Postal"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.cp}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.cp && Boolean(formik.errors.cp)}
                    helperText={formik.touched.cp && formik.errors.cp}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="hPoblacion"
                    name="hPoblacion"
                    label="Población"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.hPoblacion}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.hPoblacion &&
                      Boolean(formik.errors.hPoblacion)
                    }
                    helperText={
                      formik.touched.hPoblacion && formik.errors.hPoblacion
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <div className="row row-cols-md-3">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="entreCalle1"
                    name="entreCalle1"
                    label="Entre Calle"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.entreCalle1}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.entreCalle1 &&
                      Boolean(formik.errors.entreCalle1)
                    }
                    helperText={
                      formik.touched.entreCalle1 && formik.errors.entreCalle1
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="entreCalle2"
                    name="entreCalle2"
                    label="Y Calle"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.entreCalle2}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.entreCalle2 &&
                      Boolean(formik.errors.entreCalle2)
                    }
                    helperText={
                      formik.touched.entreCalle2 && formik.errors.entreCalle2
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="telefono"
                    name="telefono"
                    label="Teléfono"
                    type={"number"}
                    className="mb-3"
                    value={formik.values.telefono}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.telefono && Boolean(formik.errors.telefono)
                    }
                    helperText={
                      formik.touched.telefono && formik.errors.telefono
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <div className="row row-cols-md-3">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="domicilioTrabajo"
                    name="domicilioTrabajo"
                    label="Domicilio Trabajo"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.domicilioTrabajo}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.domicilioTrabajo &&
                      Boolean(formik.errors.domicilioTrabajo)
                    }
                    helperText={
                      formik.touched.domicilioTrabajo &&
                      formik.errors.domicilioTrabajo
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="coloniaTrabajo"
                    name="coloniaTrabajo"
                    label="Colonia Trabajo"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.coloniaTrabajo}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.coloniaTrabajo &&
                      Boolean(formik.errors.coloniaTrabajo)
                    }
                    helperText={
                      formik.touched.coloniaTrabajo &&
                      formik.errors.coloniaTrabajo
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="telefonoTrabajo"
                    name="telefonoTrabajo"
                    label="Teléfono Trabajo"
                    type={"number"}
                    className="mb-3"
                    value={formik.values.telefonoTrabajo}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.telefonoTrabajo &&
                      Boolean(formik.errors.telefonoTrabajo)
                    }
                    helperText={
                      formik.touched.telefonoTrabajo &&
                      formik.errors.telefonoTrabajo
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>
            </div>

            <div className="row row-cols-md-3">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="observaciones"
                  name="observaciones"
                  label="Observaciones"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.observaciones}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.observaciones &&
                    Boolean(formik.errors.observaciones)
                  }
                  helperText={
                    formik.touched.observaciones && formik.errors.observaciones
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    shrink={true}
                  >
                    Título Propiedad
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.tituloPropiedad}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    inputProps={{
                      name: "tituloPropiedad",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>No</option>
                    <option value={1}>Si</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    shrink={true}
                  >
                    Cortesía
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.cortesia}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    inputProps={{
                      name: "cortesia",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>No</option>
                    <option value={1}>Si</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>

            {props.permisoParaEditar == true ? (
              <>
                <button
                  className="my-4 btn btn-success w-100 text-white"
                  type="submit"
                  disabled={isDisabled}
                >
                  Actualizar datos generales de contacto
                </button>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};
